import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes';
import { HubspotProvider } from './context/HubspotContext';

function App() {
  
  return (
    <Router>
      <HubspotProvider>

        <AppRoutes />
      </HubspotProvider>
    </Router>
  );
}

export default App;
