import React, { useEffect, useState } from "react";
import $ from "jquery";
import "./style.css";
import { ReactComponent as IconCursor } from "../../../assets/icon/icon-cursor.svg";
import { ReactComponent as Logo } from "../../../assets/img/logo.svg";
import { ReactComponent as IconArrowCaretUp } from "../../../assets/icon/icon-arrow-caret-up.svg";
import { ReactComponent as IconArrowCaretDown } from "../../../assets/icon/icon-arrow-caret-down.svg";
import { Collapse, Form, InputGroup, Modal } from "react-bootstrap";
import SubRisk from "../../SubRisk";
import RiskScore from "../../RiskScore";
import RiskRange from "../../RiskRange";
import { NETWORK_API_TAB } from "../../../shared/constant";

const NetworkUIPanel = ({ setTab }) => {
  const [showCreateRiskWeight, setShowCreateRiskWeight] = useState(false);
  const [searchTargetScoringCollapse, setSearchTargetScoringCollapse] =
    useState(false);
  const [subRiskCollapse, setSubRiskCollapse] = useState(false);
  const [aggregateRiskCollapse, setAggregateRiskCollapse] = useState(false);
  const [showSelectBox, setShowSelectBox] = useState(false);
  const searchResult = [1, 2, 3];

  const scrollDown = (position) => {
    if (position) {
      $(".network-ui-panel .content").animate({ scrollTop: position }, 1000);
      return;
    }
    var $networkUIPanel = $(".network-ui-panel .content");
    var elementHeight = $networkUIPanel[0].scrollHeight;
    var visibleHeight = $networkUIPanel.height();

    var bottomPosition = elementHeight - visibleHeight;
    $networkUIPanel.animate({ scrollTop: bottomPosition }, 1000);
  };

  const animationAction = () => {
    $(".network-ui-panel #icon-cursor").removeAttr("class");

    $(".network-ui-panel .btn-search").css("display", "flex");
    $(".network-ui-panel .typing-text").css("display", "none");
    $(".network-ui-panel .result-content").css("display", "none");
    $(".network-ui-panel .result-items-load").css("display", "none");

    $(".network-ui-panel .dropdown-box").css("display", "none");
    setShowSelectBox(false);
    setSearchTargetScoringCollapse(false);
    setSubRiskCollapse(false);
    setAggregateRiskCollapse(false);

    // Move mouse to input field
    setTimeout(() => {
      $(".network-ui-panel #icon-cursor").css("display", "flex");
    }, 1500);

    // Show entering text
    setTimeout(() => {
      $(".network-ui-panel .typing-text").css("display", "block");
      $(".network-ui-panel #icon-cursor").css("display", "none");
    }, 3000);

    // Move to dropdown box
    setTimeout(() => {
      $(".network-ui-panel #icon-cursor").addClass("move-to-dropdown-box");
      $(".network-ui-panel #icon-cursor").css("display", "flex");
    }, 4500);

    // Open use case dropdown
    setTimeout(() => {
      setShowSelectBox(true);
    }, 6000);

    // Mova mouse to use case dropdown
    setTimeout(() => {
    $(".network-ui-panel #icon-cursor").removeAttr("class");
    $(".network-ui-panel #icon-cursor").addClass("select-item");
    }, 6500);

    // Select use case
    setTimeout(() => {
      $(".network-ui-panel .usecase-dropdown-menu li.default").addClass(
        "hover"
      );
    }, 7000);

    // Close use case dropdown
    setTimeout(() => {
      setShowSelectBox(false);
      $(".network-ui-panel .usecase-dropdown-menu li.default").removeClass(
        "hover"
      );
      $(".network-ui-panel .dropdown-box").css("display", "flex");
    }, 7500);

    // Click search button
    setTimeout(() => {
    $(".network-ui-panel #icon-cursor").removeAttr("class");
    $(".network-ui-panel #icon-cursor").addClass("move-to-search-button");
    }, 8000);

    // // Show the result search: loading posts
    setTimeout(() => {
      $(".network-ui-panel .result-content").css("display", "flex");
      $(".network-ui-panel .result-items-load").css("display", "flex");
      $(".network-ui-panel .btn-search").css("display", "none");
    }, 9500);

    // Show the result search: posts
    setTimeout(() => {
      $(".network-ui-panel .result-items-load").css("display", "none");
      $(".network-ui-panel .result-items").css("display", "flex");
    }, 11500);

    // Scroll down to Search target scoring collapse
    setTimeout(() => {
      $(".network-ui-panel .result-items-load").css("display", "none");
      $(".network-ui-panel .result-items").css("display", "flex");
      if (window.innerWidth > 768) {
        scrollDown(200);
      } else {
        scrollDown(450);
      }
    }, 12500);

    // Open Sub risk collapse
    setTimeout(() => {
      setSubRiskCollapse(true);
    }, 14000);

    // Scroll down to Aggregate risk collapse
    setTimeout(() => {
      if (window.innerWidth > 768) {
        scrollDown(500);
      } else {
        scrollDown(950);
      }
    }, 15000);

    // Open Aggregate risk collapse
    setTimeout(() => {
      setAggregateRiskCollapse(true);
    }, 16500);

    // Scroll down
    setTimeout(() => {
      if (window.innerWidth > 768) {
        scrollDown(1150);
      } else {
        scrollDown(2450);
      }
    }, 17500);

    // Move mouse to network api tab
    setTimeout(() => {
      $(".network-ui-panel #icon-cursor").removeAttr("class");
      $(".network-ui-panel #icon-cursor").addClass("move-to-network-api-tab");
    }, 20000);

    // Show network api tab
    setTimeout(() => {
      setTab(NETWORK_API_TAB)
      $(".usual-procress-panel #icon-cursor").css("display", "none");
    }, 21500);
  };

  useEffect(() => {
    var id = window.setTimeout(function () {}, 0);
    while (id--) {
      window.clearTimeout(id);
    }

    animationAction();
  }, []);
  return (
    <div className="network-ui-panel">
      <div className="content">
        <div className="logo mb-[32px]">
          <Logo />
        </div>
        <div className="w-full">
          <div className=" flex flex-wrap mx-[-12px]">
            <div className="w-full md:w-1/2 px-[12px] py-[8px]">
              <p className="text-[14px] font-medium mb-[8px]">Search Target</p>
              <div className=" h-[35px] w-full text-[14px] py-[7px] px-[10px] rounded-[5px] border-solid border-1 border-outline">
                <div className="typing-text">John’s Pizza Shop Memphis, TN</div>
              </div>
            </div>
            <div className="w-full md:w-1/2 px-[12px] py-[8px] relative">
              <p className="w-full d-flex text-[14px] font-medium mb-[8px]">
                Use Case
              </p>
              <div
                className="flex h-[35px] w-full text-[14px] py-[7px] px-[10px] rounded-[5px] border-solid border-1 border-outline"
                onClick={() => setShowSelectBox(true)}
              >
                <div className="dropdown-box hidden w-fit">SMB Onboarding</div>
                <IconArrowCaretDown className="ml-auto" />
              </div>
              {showSelectBox && (
                <ul className="usecase-dropdown-menu">
                  <li className="default">SMB Onboarding</li>
                  <li>Insider Threat</li>
                  <li>Location Security</li>
                  <li>Investigation - Person</li>
                  <li>Investigation - Entity</li>
                  <li>Onboarding Identity Confirmation</li>
                  <li>Onboarding Fraud Risk</li>
                  <li>Regulatory Risk</li>
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="result-content w-full hidden flex-col gap-[12px]">
          <div className="flex w-full pb-[15px] ">
            <div className="w-full max-w-[550px] flex flex-column gap-[12px]">
              <div className="result-items-load hidden flex-col gap-[16px] pt-[28px] overflow-hidden">
                {searchResult.map((item) => (
                  <div className="flex flex-col gap-[4px] text-[10px]">
                    <div className="h-[30px] w-5/12 bg-outline"></div>
                    <div className="h-[30px] w-4/12 bg-outline"></div>
                    <div className="h-[30px] w-full bg-outline"></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {searchResult.map((item) => (
            <div className="collapse-card">
              <div className="w-full flex flex-wrap">
                <div className="w-full lg:w-7/12 ">
                  <div className="card-view">
                    <div className="flex flex-col md:flex-row gap-[8px]">
                      <img
                        className="w-[24px] h-[24px]"
                        src={require("../../../assets/img/img-avatar-empty.png")}
                        alt=""
                      />
                      <div>
                        <p>Papa John’s Pizza</p>
                        <p className="text-gray text-[12px]">
                          https://locations.papajohns.com
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="text-[14px]">Poplar Ave - Memphis</p>
                      <p className="text-gray text-[12px]">
                        Order online or call (901) 766-9988 now for the best
                        pizza deals. Taste our latest menu options for pizza,
                        breadsticks and wings. Available for delivery or ...{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-5/12 p-[8px] flex  flex-col gap-[20px]">
                  <SubRisk
                    name="Risk Key 1"
                    percent={87}
                    level="high"
                    className="border-0 p-0"
                  />
                  <SubRisk
                    name="Sentiment"
                    percent={37}
                    level="medium"
                    className="border-0 p-0"
                  />
                </div>

                <div className="collapse-card my-[12px]">
                  <div
                    className="w-full"
                    onClick={() => setSubRiskCollapse(!subRiskCollapse)}
                    aria-controls="example-collapse-text"
                    aria-expanded={subRiskCollapse}
                  >
                    <div className="flex justify-between">
                      <span className="text-[14px] font-medium">
                        Sub Risk Categories
                      </span>
                      {subRiskCollapse ? (
                        <IconArrowCaretUp />
                      ) : (
                        <IconArrowCaretDown />
                      )}
                    </div>
                  </div>
                  <Collapse in={subRiskCollapse}>
                    <div className="w-full flex flex-wrap mx-[-6px]">
                      <div className="w-full lg:w-1/2 p-[6px]">
                        <SubRisk name="Location" percent={81.3} level="high" />
                      </div>
                      <div className="w-full lg:w-1/2 p-[6px]">
                        <SubRisk name="Fraud" percent={63.4} level="high" />
                      </div>
                      <div className="w-full lg:w-1/2 p-[6px]">
                        <SubRisk name="Identity" percent={8.3} level="low" />
                      </div>
                      <div className="w-full lg:w-1/2 p-[6px]">
                        <SubRisk
                          name="Regulation"
                          percent={47.6}
                          level="medium"
                        />
                      </div>
                    </div>
                  </Collapse>
                </div>

                <div className="collapse-card ">
                  <div
                    className="w-full"
                    onClick={() =>
                      setAggregateRiskCollapse(!aggregateRiskCollapse)
                    }
                    aria-controls="example-collapse-text"
                    aria-expanded={aggregateRiskCollapse}
                  >
                    <div className="flex justify-between">
                      <span className="text-[14px] font-medium">
                        Aggregate Risk Scores
                      </span>
                      {aggregateRiskCollapse ? (
                        <IconArrowCaretUp />
                      ) : (
                        <IconArrowCaretDown />
                      )}
                    </div>
                  </div>
                  <Collapse in={aggregateRiskCollapse}>
                    <div>
                      <div className="flex flex-col md:flex-row gap-[12px]">
                        <button className="bg-lightGreen font-medium py-[8px] text-[12px]">
                          Update Risk Weights
                        </button>
                        <button className="bg-lightGreen font-medium py-[8px] text-[12px]">
                          Update Risk Keys
                        </button>
                        <button
                          className="bg-lightGreen font-medium py-[8px] text-[12px]"
                          onClick={() => setShowCreateRiskWeight(true)}
                        >
                          Create Risk Weight
                        </button>
                      </div>
                      <div className="w-full flex flex-wrap mx-[-6px] ">
                        <RiskScore
                          name="Social Media"
                          percent={47}
                          percentChange={12}
                          riskLevel={"medium"}
                          className="w-1/2 md:w-1/4 lg:w-1/5"
                        />
                        <RiskScore
                          name="Open"
                          percent={74}
                          percentChange={17}
                          riskLevel={"high"}
                          className="w-1/2 md:w-1/4 lg:w-1/5"
                        />
                        <RiskScore
                          name="SSL"
                          percent={8}
                          percentChange={2}
                          riskLevel={"low"}
                          className="w-1/2 md:w-1/4 lg:w-1/5"
                        />
                        <RiskScore
                          name="Cyber Mentions"
                          percent={68}
                          percentChange={-4}
                          riskLevel={"medium"}
                          className="w-1/2 md:w-1/4 lg:w-1/5"
                        />
                        <RiskScore
                          name="Data Breach"
                          percent={10}
                          percentChange={-10}
                          riskLevel={"low"}
                          className="w-1/2 md:w-1/4 lg:w-1/5"
                        />
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button className="btn-search">Search</button>
      </div>
      <IconCursor className="icon " id="icon-cursor" />

      <Modal
        size="lg"
        centered
        show={showCreateRiskWeight}
        onHide={() => setShowCreateRiskWeight(false)}
      >
        <Modal.Body>
          <div className="flex flex-col gap-[24px] text-[14px] font-medium">
            <div className="flex justify-between">
              <p className="text-[18px]">
                Create a new Risk Weighting Category
              </p>
              <div>
                <button onClick={() => setShowCreateRiskWeight(false)}>
                  Cancel
                </button>
                <button
                  onClick={() => setShowCreateRiskWeight(false)}
                  className="black ml-[12px]"
                >
                  Create
                </button>
              </div>
            </div>

            <div>
              <p>Name</p>
              <InputGroup className="mt-[8px]">
                <Form.Control
                  className="py-[12px] px-[14px] rounded-[12px]"
                  placeholder="Enter Name"
                />
              </InputGroup>
            </div>

            <div className="flex justify-between">
              <p className="text-[18px]">Set Your Risk Attribute Priority</p>
              <div className="flex border-solid border-1 border-outline px-[8px] py-[4px] rounded-[100px] text-[12px]">
                <span>1 = </span>
                <span className="text-success ml-1"> Low</span>
                <div className="mx-2 w-[2px] bg-outline"></div>
                <span>100 =</span>
                <span className="text-danger ml-1"> High</span>
              </div>
            </div>

            <div className="flex flex-col gap-[12px]">
              <RiskRange name="Social Media" percent={47} />
              <RiskRange name="Domain Registrant" percent={23} />
              <RiskRange name="Hosting Location" percent={85} />
              <RiskRange name="Website Virus" percent={15} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NetworkUIPanel;
