import React from 'react';
import { ReactComponent as IconFacebook }  from '../../../assets/icon/icon-facebook.svg'
import { ReactComponent as IconTwitter}  from '../../../assets/icon/icon-twitter.svg'
import { ReactComponent as IconLinkin }  from '../../../assets/icon/icon-linkin.svg'
import { ReactComponent as Logo }  from '../../../assets/img/logo.svg'
import './style.css'

const Footer = () => {
  const scrollToSection = ( sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <footer className='py-[80px] md:py-[128px]'>
      <Logo className="logo"/>
      <div className='flex gap-2'>
        <a  onClick={(event) => scrollToSection('section-our-solution')} className='text-white text-[14px] font-medium cursor-pointer hover:underline p-[12px]'>Fusion Network</a>
        <a  onClick={(event) => scrollToSection('section-how-it-work-2')} className='text-white text-[14px] font-medium cursor-pointer hover:underline p-[12px]'>ROI w TM</a>
        <a  onClick={(event) => scrollToSection('section-faq')} className='text-white text-[14px] font-medium cursor-pointer hover:underline p-[12px]'>FAQ</a>
        <a  onClick={(event) => scrollToSection('section-our-solution')} className='text-white text-[14px] font-medium cursor-pointer hover:underline p-[12px]'>Grow with TM Fusion</a>
        <a  onClick={(event) => scrollToSection('section-how-it-work-1')} className='text-white text-[14px] font-medium cursor-pointer hover:underline p-[12px]'>How it Works</a>
      </div>
      <div className='flex gap-[28px]'>
        <a href="https://www.facebook.com/MinderThreat" target='_blank'> <IconFacebook /> </a>
        <a href="https://twitter.com/threatminder" target='_blank'> <IconTwitter /> </a>
        <a href="https://www.linkedin.com/company/threat-minder" target='_blank'> <IconLinkin /> </a>
      </div>
    </footer>
  );
};

export default Footer;
