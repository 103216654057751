import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { ReactComponent as IconArrowUp } from "../assets/icon/icon-arrow-up.svg";
import { ReactComponent as IconArrowDown } from "../assets/icon/icon-arrow-down.svg";

const HIGH = "high";
const MEDIUM = "medium";
const LOW = "low";

const styles = {
  progressContainer: {
    padding: '8px',
    minWidth: "166px",
  },
  progressCard: {
    boxShadow:
      "0px 1px 2px 0px rgba(4, 9, 25, 0.06), 0px 1px 3px 0px rgba(4, 9, 25, 0.10)",
    borderRadius: "8px",
    gap: "16px",
    padding: "18px",
    fontWeight: "500",
    color: "#111827",
    minWidth: "150px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

const RiskScore = ({
  name,
  riskLevel = "low",
  percent,
  percentChange,
  className,
}) => {
  const getProgressColor = (riskLevel) => {
    switch (riskLevel.toLowerCase()) {
      case HIGH:
        return "#B91C1C";
      case MEDIUM:
        return "#FB923C";
      case LOW:
        return "#6BC78A";
      default:
        return "suc#6BC78Acess";
    }
  };
  return (
    <div className={className} style={styles.progressContainer}>
      <div style={styles.progressCard} >
        <span>{name}</span>
        <div className="w-full relative">
          <div
            className="flex items-center absolute right-1/2 top-1/4 translate-x-1/2"
            style={{ color: getProgressColor(riskLevel) }}
          >
            {percentChange < 0 ? (
              <IconArrowDown height={12} width={12} />
            ) : (
              <IconArrowUp height={12} width={12} />
            )}
            <span className="text-[12px] ml[2px]">
              {Math.abs(percentChange)}
            </span>
          </div>
          <CircularProgressbar
            value={percent}
            text={`${percent}%`}
            styles={buildStyles({
              pathColor: getProgressColor(riskLevel),
              textColor: "#111827",
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default RiskScore;
