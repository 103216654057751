export const apiData = {
  uuid: "b8f550d6-a7b7-4f49-b419-9f2ae8a2e0ca",
  name: "TEST 1",
  options: {
    tag: {
      id: "my-id-123",
    },
    categoryWeighting: {
      web: 100,
    },
    weightedRiskKeywords: [
      {
        weight: 20,
        keyword: "bullshit",
        categories: ["Anger"],
      },
      {
        weight: 10,
        keyword: "standoff",
        categories: ["Threat", "Anger"],
      },
    ],
  },
  usecase: "default",
  scoredDocs: {
    score: {
      sigmoidScaled: 50,
      eddScaled: 78.98639287992353,
      raw: 50,
    },
    categories: {
      web: {
        score: {
          sigmoidScaled: 50,
          eddScaled: 78.98639287992353,
          raw: 50,
        },
        docs: [
          {
            weightedRiskKeywordWeightByCategory: {
              Anger: 50,
              Threat: 10,
            },
            documentType: "web",
            documentId: 9000000008,
            metrics: [
              {
                analytics: {
                  weightedRiskKeywordRawScore: {
                    sigmoidScaled: 50,
                    eddScaled: 78.98639287992353,
                    raw: 50,
                  },
                  sentiment: {
                    sentiment: "negative",
                    confidence: 0.9393385350704193,
                  },
                  keywordMatches: [],
                  requiredKeywordMatches: [],
                  weightedRiskKeywordMatches: [
                    {
                      textSource: "title",
                      targetPhrase: "standoff",
                      index: 55,
                      weight: 10,
                      categories: ["Threat", "Anger"],
                      targetSource: "weightedRiskKeywords",
                    },
                    {
                      textSource: "content",
                      targetPhrase: "bullshit",
                      index: 130,
                      weight: 20,
                      categories: ["Anger"],
                      targetSource: "weightedRiskKeywords",
                    },
                    {
                      textSource: "content",
                      targetPhrase: "bullshit",
                      index: 265,
                      weight: 20,
                      categories: ["Anger"],
                      targetSource: "weightedRiskKeywords",
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  },
  overallWeightedRiskKeywordWeightByCategory: {
    Anger: 50,
    Threat: 10,
  },
};
