import React from 'react';


const JsonBlock = ({data}) => {
  const jsonString = JSON.stringify(data, null, 2); // Tham số thứ hai và thứ ba để định dạng và thụ động hóa JSON
  return (
    <pre>
      <code>{jsonString}</code>
    </pre>
  );
}
export default JsonBlock;
