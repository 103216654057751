import React, { useEffect, useState } from "react";
import $ from "jquery";
import { apiData } from "../../../shared/data";
import "./style.css";
import JsonBlock from "../../JsonBlock";
import { ReactComponent as IconSet } from "../../../assets/icon/icon-set.svg";
import { ReactComponent as IconCursor } from "../../../assets/icon/icon-cursor.svg";
import { ReactComponent as Logo } from "../../../assets/img/logo.svg";
import { ReactComponent as IconArrowCaretDown } from "../../../assets/icon/icon-arrow-caret-down.svg";
import { USUAL_PROCRESS_TAB } from "../../../shared/constant";

const NetworkAPIPanel = ({setTab}) => {
  const [showSelectBox, setShowSelectBox] = useState(false);

  const scrollDown = () => {
    var $networkUIPanel = $(".network-api-panel .content");
    var elementHeight = $networkUIPanel[0].scrollHeight;
    var visibleHeight = $networkUIPanel.height();

    var bottomPosition = elementHeight - visibleHeight;
    $networkUIPanel.animate({ scrollTop: bottomPosition }, 5000);
  };

  const animationAction = () => {
    $(".network-api-panel .btn-search").css("display", "flex");
    $(".network-api-panel #icon-cursor").removeAttr("class");
    $(".network-api-panel .typing-text").css("display", "none");
    $(".network-api-panel .result-content").css("display", "none");
    $(".network-api-panel .result-items").css("display", "none");
    setShowSelectBox(false);

    // Move mouse to input field
    setTimeout(() => {
      $(".network-api-panel #icon-cursor").css("display", "flex");
    }, 1500);

    // Show entering text
    setTimeout(() => {
      $(".network-api-panel #icon-cursor").css("display", "none");
      $(".network-api-panel .typing-text").css("display", "block");
    }, 3000);

    // Move to dropdown box
    setTimeout(() => {
      $(".network-api-panel #icon-cursor").addClass("move-to-dropdown-box");
      $(".network-api-panel #icon-cursor").css("display", "flex");
    }, 4500);

    // Open use case dropdown
    setTimeout(() => {
      setShowSelectBox(true);
    }, 6000);

    // Mova mouse to use case dropdown
    setTimeout(() => {
      $(".network-api-panel #icon-cursor").addClass("select-item");
    }, 6500);

    // Select use case
    setTimeout(() => {
      $(".network-api-panel .usecase-dropdown-menu li.default").addClass(
        "hover"
      );
    }, 7000);

    // Close use case dropdown
    setTimeout(() => {
      setShowSelectBox(false);
      $(".network-api-panel .usecase-dropdown-menu li.default").removeClass(
        "hover"
      );
      $(".network-api-panel .dropdown-box").css("display", "flex");
    }, 7500);

    // Click search button
    setTimeout(() => {
      $(".network-api-panel #icon-cursor").addClass("move-to-search-button");
      $(".network-api-panel #icon-cursor").css("display", "flex");
    }, 8000);

    // Show the result API
    setTimeout(() => {
      $(".network-api-panel .result-content").css("display", "flex");
      $(".network-api-panel .btn-search").css("display", "none");
    }, 9500);

    // Scroll down
    setTimeout(() => {
      scrollDown();
    }, 10000);

    // Move mouse to usual process tab
    setTimeout(() => {
      $(".network-api-panel #icon-cursor").removeAttr("class");
      $(".network-api-panel #icon-cursor").addClass("move-to-usual-process-tab");
    }, 16000);

    // Show usual process tab
    setTimeout(() => {
      setTab(USUAL_PROCRESS_TAB)
      $(".usual-procress-panel #icon-cursor").css("display", "none");
    }, 17500);
  };

  useEffect(() => {
    var id = window.setTimeout(function () {}, 0);

    while (id--) {
      window.clearTimeout(id);
    }
    animationAction();
  }, []);
  return (
    <div className="network-api-panel">
      <div className="content">
        <div className="logo">
          <Logo />
        </div>
        <div className="w-full">
          <div className=" flex flex-wrap mx-[-12px]">
            <div className="w-full md:w-1/2 px-[12px] py-[8px]">
              <p className="text-[14px] font-medium mb-[8px]">Search Target</p>
              <div className=" h-[35px] w-full text-[14px] py-[7px] px-[10px] rounded-[5px] border-solid border-1 border-outline">
                <div className="typing-text">John’s Pizza Shop Memphis, TN</div>
              </div>
            </div>
            <div className="w-full md:w-1/2 px-[12px] py-[8px] relative">
              <p className="text-[14px] font-medium mb-[8px]">Use Case</p>
              <div
                className="flex h-[35px] w-full text-[14px] py-[7px] px-[10px] rounded-[5px] border-solid border-1 border-outline"
                onClick={() => setShowSelectBox(true)}
              >
                <div className="dropdown-box hidden w-fit">SMB Onboarding</div>
                <IconArrowCaretDown className="ml-auto" />
              </div>
              {showSelectBox && (
                <ul className="usecase-dropdown-menu">
                  <li className="default">SMB Onboarding</li>
                  <li>Insider Threat</li>
                  <li>Location Security</li>
                  <li>Investigation - Person</li>
                  <li>Investigation - Entity</li>
                  <li>Onboarding Identity Confirmation</li>
                  <li>Onboarding Fraud Risk</li>
                  <li>Regulatory Risk</li>
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="result-content w-full hidden flex-col gap-[12px]">
          <div
            className="w-full py-[24px] h-full overflow-y-auto text-white"
            style={{ background: "#111827" }}
          >
            <div className="tab-panel-main px-[24px] lg:px-[48px]">
              <JsonBlock data={apiData} />
              <IconSet className="absolute right-[16px] top-0" />
            </div>
          </div>
        </div>
        <button className="btn-search">Search</button>
      </div>
      <IconCursor className="icon " id="icon-cursor" />
    </div>
  );
};

export default NetworkAPIPanel;
