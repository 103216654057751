import React, { createContext, useState } from "react";

export const HubspotContext = createContext();

export const HubspotProvider = ({ children }) => {
  const [showHubspotModal, setShowHubspotModal] = useState(false);

  const openHubspotForm = () => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        portalId: "44259663",
        formId: "b8f1a25a-d841-4902-8fdc-4df9ba43496a",
        target: ".hubspotForm",
      });
    }
    setShowHubspotModal(true);
  };
  const closeHubspotForm = () => {
    setShowHubspotModal(false);
  };

  return (
    <HubspotContext.Provider
      value={{
        openHubspotForm,
        closeHubspotForm,
        showHubspotModal,
      }}
    >
      {children}
    </HubspotContext.Provider>
  );
};
