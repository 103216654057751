import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.css";
import $ from "jquery";

import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { ReactComponent as IconSearch }  from '../../assets/icon/icon-search.svg'
import { ReactComponent as IconChartBarVertical }  from '../../assets/icon/icon-chart-bar-vertical-01.svg'
import { ReactComponent as IconChartLine }  from '../../assets/icon/icon-chart-line.svg'
import { ReactComponent as IconSettingsFuture }  from '../../assets/icon/icon-settings-future.svg'
import { ReactComponent as IconTableAdd }  from '../../assets/icon/icon-table-add.svg'
import { ReactComponent as IconData }  from '../../assets/icon/icon-data.svg'
import { ReactComponent as IconArrowUp }  from '../../assets/icon/icon-arrow-up.svg'
import { ReactComponent as IconArrowCaretUp }  from '../../assets/icon/icon-arrow-caret-up.svg'
import { ReactComponent as IconArrowDown }  from '../../assets/icon/icon-arrow-down.svg'
import { ReactComponent as IconArrowCaretDown }  from '../../assets/icon/icon-arrow-caret-down.svg'
import { ReactComponent as IconArrowRight }  from '../../assets/icon/icon-arrow-right.svg'
import { ReactComponent as IconArrowChevronRight }  from '../../assets/icon/icon-arrow-chevron-right.svg'
import { ReactComponent as IconArrowChevronLeft }  from '../../assets/icon/icon-arrow-chevron-left.svg'
import { ReactComponent as IconCheck }  from '../../assets/icon/icon-check.svg'
import { ReactComponent as IconLayer }  from '../../assets/icon/icon-layer.svg'
import { ReactComponent as IconSlider }  from '../../assets/icon/icon-slider.svg'
import { ReactComponent as IconNotebook }  from '../../assets/icon/icon-notebook.svg'
import { ReactComponent as IconChartPie }  from '../../assets/icon/icon-chart-pie.svg'
import { ReactComponent as IconShield }  from '../../assets/icon/icon-shield.svg'
import { ReactComponent as IconWindowCodeBlock }  from '../../assets/icon/icon-window-code-block.svg'
import UsualProcressPanel from "./usual-process-panel";
import NetworkUIPanel from "./network-ui-panel";
import NetworkAPIPanel from "./network-api-panel";
import { HubspotContext } from "../../context/HubspotContext";
import { USUAL_PROCRESS_TAB, NETWORK_UI_TAB, NETWORK_API_TAB } from "../../shared/constant";


const HomePage = () => {
  const { openHubspotForm } = useContext(HubspotContext);

  const [tab, setTab] = useState(USUAL_PROCRESS_TAB);
  const testimonialsCarouselRef1 = useRef(null)
  const testimonialsCarouselRef2 = useRef(null)
  const testimonialsCarouselRef3 = useRef(null)
  const responsive = {
    large: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1200, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 464 },
      items: 1,
    }
  };

  const testimonialsCarouselResponsive = {
    large: {
      breakpoint: { max: 3000, min: 1200 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1200, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 464 },
      items: 1,
    }
  };

  const [faq1, setFaq1] = useState([
    {
      question: "Do you offer API or UI access?",
      answer: "We offer both.  You can use either our API or our UI.  We also can provide datasets.  Contact us for more details.",
      isOpen: false,
    },
    {
      question: "Is the data real time?",
      answer: "Our Analytics occurs in real time.  Our data is anywhere from real time to a few minutes – depending on the data type and data source.  Contact us for more details.",
      isOpen: false,
    },
    {
      question: "How many data sources/platforms do you provide?",
      answer: "We provide thousands of platforms and datasets – both unstructured and structured, public records and much more. Contact us for more details.",
      isOpen: false,
    },
    {
      question: "How do you score and map data?",
      answer: "We score data with our unique and powerful AI analytics.  This system provides unique scoring for each different Use Case.  We then map data to specific categories, rank the data, and provide detailed metrics as to the WHY the information has been scored in certain ways.  Contact us for more details",
      isOpen: false,
    },
    {
      question: "How secure is my data?",
      answer: "Super secure.  We use all best practices and regulatory compliance requirements to ensure your PII and corporate data is safe",
      isOpen: false,
    },
    {
      question: "Tell me more about your AI",
      answer: ` We use AI in multiple ways
      \n\t - To confirm scoring
      \n\t - To enhance reporting
      \n\t - To augment and update our algorithm for your specific Use Case
      \n\t - To create reports`,
      isOpen: false,
    }
  ])
  const [faq2, setFaq2] = useState([
    {
      question: "We already do analytics?",
      answer: "No problem.  We typically work with organizations that have analytics, people, and algorithms already in place.  We can augment, supplant, or work with your existing data and scoring systems.  Contact us for more details",
      isOpen: false,
    },
    {
      question: "We have a lot of analysts already working through results?",
      answer: "This use case is our specialty!  We make those analysts so much more efficient.  In fact, often we see a 78-96% increase in efficiency from analysts and 2ndary review processes.  We allow those analysts to focus on the data that matters – not the junk that doesn’t.",
      isOpen: false,
    },
    {
      question: "We don’t really need custom scoring?",
      answer: "Great.  You never have to change scoring.  Simply use the default Use Cases and scoring algorithms already in place",
      isOpen: false,
    },
    {
      question: "Can we change the analytics in real time?",
      answer: "Absolutely!  Our specialty is allowing people to change scoring, weighting, and search criteria in real time.  Then watch as the overall results change.  Analysts love this capability",
      isOpen: false,
    },
    {
      question: "We already have data coming in?",
      answer: "You can choose our data, you can use your own data – We can score it all!",
      isOpen: false,
    },
  ])

  

  const collapseFAQ1 = (item) => {
    const updateFaq1 = faq1.map((faq) => {
      if (faq.question === item.question) {
        return {
          ...faq,
          isOpen: !faq.isOpen
        }
      }
      return faq
    })
    setFaq1(updateFaq1)
  }

  const collapseFAQ2 = (item) => {
    const updateFaq2 = faq2.map((faq) => {
      if (faq.question === item.question) {
        return {
          ...faq,
          isOpen: !faq.isOpen
        }
      }
      return faq
    })
    setFaq2(updateFaq2)
  }

  const handleMoveTestimonialsCarousel = (direction) => {
    if(direction === 'next') {
      testimonialsCarouselRef1.current.next()
      testimonialsCarouselRef2.current.next()
      testimonialsCarouselRef3.current.next()
    } else {
      testimonialsCarouselRef1.current.previous()
      testimonialsCarouselRef2.current.previous()
      testimonialsCarouselRef3.current.previous()
    }
  }



  const [textIndex, setTextIndex] = useState(0);
  const textArray = ["Simplified", "Analyzed", "Amplified", "Force Multiplied"];
  useEffect(() => {
    $(".scrollButton").css("top", `${-(textIndex)*41}px`)
    const interval = setInterval(() => {
    setTextIndex((prevIndex) => (prevIndex + 1) % textArray.length);
    $(".scrollButton").css("top", `${-(textIndex)*41}px`)
  }, 2000); 

    return () => clearInterval(interval);
  }, [textArray]);

  
  return (
    <div className="usual-process-content">
    <div className="tab-container">
      <div className="px-[24px] pt-[64px] lg:pt-[100px] flex flex-col items-center">
        <button className="black relative " style={{ borderRadius: "100px" }}>
            Search <span className="text-black">{textArray[textIndex]}</span>
            <div className="flex flex-col scrollButton">
              <button className="black pl-0 w-max scrollText">Simplified</button>
              <button className="black pl-0 scrollText">Analyzed</button>
              <button className="black pl-0 scrollText">Amplified</button>
              <button className="black pl-0 w-max scrollText">Force Multiplied</button>
            </div>
        </button>
        <h1 className="mt-3 z-10">ThreatMinder</h1>
        <h1 className="mb-3 z-10">Fusion Network</h1>
        <p className="fs-6 mb-1 text-dark-gray text-center">
          Focus on results that matter, not the junk.
        </p>
        <p className="fs-6 mb-1 text-dark-gray text-center">
          AI based search analytics, Use Case driven, mapped for:
        </p>
        <p className="fs-6 mb-1 text-dark-gray text-center">
          Risk, Confirmation, Verification, Identification
        </p>
        <div
          className="flex bg-gray-100"
          style={{
            borderRadius: "12px",
            marginTop: "36px",
            marginBottom: "80px",
            background: "#F3F4F6",
          }}
        >
          <button
            className={`btn-tab flex items-center border-0 ${
              tab === USUAL_PROCRESS_TAB ? "black" : ""
            }`}
            onClick={() => setTab(USUAL_PROCRESS_TAB)}
          >
            <IconSearch className="icon mr-1"/>
            Usual Process
          </button>
          <button
            className={`btn-tab flex items-center border-0 ${
              tab === NETWORK_UI_TAB ? "black" : ""
            }`}
            onClick={() => setTab(NETWORK_UI_TAB)}
          >
            <IconShield className="icon mr-1"/>
            With TM Fusion Network UI
          </button>
          <button
            className={`btn-tab flex items-center border-0 ${
              tab === NETWORK_API_TAB ? "black" : ""
            }`}
            onClick={() => setTab(NETWORK_API_TAB)}
          >
            <IconWindowCodeBlock className="icon mr-1"/>
            With TM Fusion Network API
          </button>
        </div>
      </div>
    </div>
      <section id="section-hero" className=" p-[0]">
        <div className="background-image ">
          <div className="tab-panel-container">
            <div className="tab-panel">
              <div className="tab-panel-header">
                <div className="search-bar text-center">Generic Search</div>
              </div>
              <div className="tab-panel-main h-[600px]">
                { tab === USUAL_PROCRESS_TAB && <UsualProcressPanel setTab={setTab}/>}
                { tab === NETWORK_UI_TAB && <NetworkUIPanel setTab={setTab}/>}
                { tab === NETWORK_API_TAB && <NetworkAPIPanel setTab={setTab}/>}
              </div>
            </div>
            <button className="black btn-contact" onClick={openHubspotForm}>Contact us today</button>
          </div>
        </div>
      </section>


      <section id="section-logocloud">
          <p className="text-center">ThreatMinder is loved and trusted by top companies</p>
          <div className="logo-container">
              <img className="logo" alt="" src={require('../../assets/img/img-logo-raytheon-1.png')}/>
              <img className="logo" alt="" src={require("../../assets/img/img-logo-abbott-laboratories-1.png")}/>
              <img className="logo" alt="" src={require("../../assets/img/img-logo-delta.png")}/>
              <img className="logo" alt="" src={require("../../assets/img/img-logo-lockheed-martin-1.png")}/>
              <img className="logo" alt="" src={require("../../assets/img/img-logo-aflac-1.png")}/>
              <img className="logo" alt="" src={require("../../assets/img/img-logo-allied-universal.png")}/>
          </div>
      </section>


      <section id="section-our-solution" className=" p-[0]">
        <div className="background-image">
          <div className="flex flex-col items-center justify-center">
            <span className="text-darkGreen text-sm text-center">Our Solutions</span>
            <h1 className="mt-2 mb-3 text-[28px] md:text-[40px] text-center">Grow with TM Fusion Network</h1>
            <p className="text-gray text-base text-center  max-w-[446px]">
                Delivering AI based Analytics to automate your manual processes at-scale, in real-time.
            </p>
          </div>

          <div className="w-full flex justify-center mt-[8px] md:mt-[32px]">
          <Carousel
              responsive={responsive}
              infinite={true}
              containerClass={`custom-carousel-container`}
              itemClass="card-item-container"
            >

            <div className="card-item-content">
                <div className="box-icon">
                <IconChartBarVertical className="icon mx-2" color="#374151" />
                </div>
                <div className="mx-2">
                  <p className="font-medium text-base text-gray">API based AI Analytics</p>
                  <p className="text-sm text-gray mt-2">Custom, Mapped, Integrated Analytics</p>
                </div>
                <div className="line h-[2px] w-full mt-auto">
                  <div className="progress-line h-[2px] w-5/12"></div>
                </div>
            </div>
            <div className="card-item-content">
                <div className="box-icon">
                <IconChartLine className="icon mx-2" color="#374151" />
                </div>
                <div className="mx-2">
                  <p className="font-medium text-base text-gray">Managing Data Knowledge Across your Organization</p>
                  <p className="text-sm text-gray mt-2">Your + Our Data allows comprehensive decisioning</p>
                </div>
                <div className="line h-[2px] w-full mt-auto">
                  <div className="progress-line h-[2px] w-5/12"></div>
                </div>
            </div>
            <div className="card-item-content">
                <div className="box-icon">
                <IconSettingsFuture className="icon mx-2" color="#374151" />
                </div>
                <div className="mx-2">
                  <p className="font-medium text-base text-gray">Use Case Driven</p>
                  <p className="text-sm text-gray mt-2">Each Use Case scores data differently</p>
                </div>
                <div className="line h-[2px] w-full mt-auto">
                  <div className="progress-line h-[2px] w-5/12"></div>
                </div>
            </div>
            <div className="card-item-content">
                <div className="box-icon">
                <IconTableAdd className="icon mx-2" color="#374151" />
                </div>
                <div className="mx-2">
                  <p className="font-medium text-base text-gray">Real Time Algorithm Customization</p>
                  <p className="text-sm text-gray mt-2">Change scorring in real time to see test different scenarios</p>
                </div>
                <div className="line h-[2px] w-full mt-auto">
                  <div className="progress-line h-[2px] w-5/12"></div>
                </div>
            </div>
            <div className="card-item-content">
                <div className="box-icon">
                <IconData className="icon mx-2" color="#374151" />
                </div>
                <div className="mx-2">
                  <p className="font-medium text-base text-gray">Data Focused</p>
                  <p className="text-sm text-gray mt-2">Augment your data with our data</p>
                </div>
                <div className="line h-[2px] w-full mt-auto">
                  <div className="progress-line h-[2px] w-5/12"></div>
                </div>
            </div>
            </Carousel>
          </div>
        </div>
        <div className="px-[24px] lg:px-[112px]">
          <div className="business-page-image ">
              <img className="px-[20px] lg:px-[100px] pt-[24px] lg:pt-[64px]" src={require('../../assets/img/img-bussiness-tile-page.png')} alt=""/>
          </div>
        </div>
      </section>


      <section id="section-how-it-work-1" className=" py-[80px] md:py-[128px]">
        <div className="w-full flex flex-col gap-[56px] items-center">
          <div className="flex flex-col items-center">
            <span className="text-darkGreen text-sm text-center">How it works</span>
            <h1 className="text-white mt-2 mb-3 text-[28px] md:text-[40px] text-center">4 simple steps</h1>
            <p className="text-lightGray text-base text-center max-w-[640px]">
              Transforming complex data into actionable insights is a simple click with ThreatMinder Fusion Network, providing streamlined and analyzed search results at scale.
            </p>
          </div>
          <div className="flex flex-wrap px-[12px]">
            <div className=" w-full lg:w-1/4 p-1">
              <div className="card-background">
                <div className="card-content gap-[24px] lg:gap-[180px]">
                    <div className="w-full flex justify-between">
                      <IconLayer className="icon"/>
                      <span className="step font-bold text-xs text-gray600">STEP 1</span>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-lg text-gray600 font-medium text1">Define the Use Case</p>
                      <p className="text-base text-gray600 text2">Either use our 55 Default Use Cases or Create Unlimited New Use Cases</p>
                    </div>
                </div>
              </div>
            </div>

            <div className="w-full lg:w-1/4 p-1">
              <div className="card-background">
                <div className="card-content gap-[24px] lg:gap-[180px]">
                    <div className="w-full flex justify-between">
                      <IconSlider className="icon"/>
                      <span className="step font-bold text-xs text-gray600">STEP 2</span>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-lg text-gray600 font-medium text1">Customize Scoring</p>
                      <p className="text-base text-gray600 text2">Make the algorithm specific to your needs, in real-time, anytime</p>
                    </div>
                </div>
              </div>
            </div>

            <div className="w-full lg:w-1/4 p-1">
              <div className="card-background">
                <div className="card-content gap-[24px] lg:gap-[180px]">
                    <div className="w-full flex justify-between">
                      <IconNotebook className="icon"/>
                      <span className="step font-bold text-xs text-gray600">STEP 3</span>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-lg text-gray600 font-medium text1">Receive the AI based Scoring Magic</p>
                      <p className="text-base text-gray600 text2">Receive the Scoring and the WHY behind it!</p>
                      <p className="text-base text-gray600 text2">Scored, Mapped, and Analyzed</p>
                      
                    </div>
                </div>
              </div>
            </div>

            <div className="w-full lg:w-1/4 p-1">
              <div className="card-background">
                <div className="card-content gap-[24px] lg:gap-[180px]">
                    <div className="w-full flex justify-between">
                      <IconChartPie className="icon"/>
                      <span className="step font-bold text-xs text-gray600">STEP 4</span>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="text-lg text-gray600 font-medium text1">Change Algorithms in Real Time</p>
                      <p className="text-base text-gray600 text2">Update Scoring and Weighting based on results.</p>
                    </div>
                </div>
              </div>
            </div>

          </div>
          <button className="text-white" onClick={openHubspotForm}>Book a demo</button>

        </div>
      </section>

      <section id="section-how-it-work-2" className=" py-[80px] md:py-[128px]">
        <div className="w-full">
          <div className="flex flex-col items-center mb-[56px]">
            <span className="text-darkGreen text-sm text-center">ROI with TM</span>
            <h1 className="mt-2 mb-3 text-[28px] md:text-[40px] text-center">The Usual Process Incurs These Costs</h1>
            <p className="text-gray text-base text-center max-w-[446px]">
              Your Costs Today. Current processes result in expensive and inefficient results
            </p>
            </div>
            <div className="flex flex-wrap mx-[0] lg:mx-[-24px] ">
              <div className="w-full lg:w-5/12 px-[0] lg:px-[12px] py-[12px]">
                <div className="card-view p-[24px] lg:p-[48px] pb-0">
                    <span className="text-gray text-sm">Did you know?</span>
                    <h4 className="text-xl mt-2 mb-4">Average Cost of:</h4>
                    <div className="card-content">
                      <div className="w-full flex justify-between pb-4" style={{borderBottom: "1px solid #E5E7EB"}}>
                        <span className="text-black text-sm">Fraud Event </span>
                        <span className="text-black text-sm font-medium">$30,000</span>
                      </div>
                      <div className="w-full flex justify-between pb-4" style={{borderBottom: "1px solid #E5E7EB"}}>
                        <span className="text-black text-sm">False Identity Event </span>
                        <span className="text-black text-sm font-medium">$36,000</span>
                      </div>
                      <div className="w-full flex justify-between pb-4" style={{borderBottom: "1px solid #E5E7EB"}}>
                        <span className="text-black text-sm">Security Incident Event </span>
                        <span className="text-black text-sm font-medium">$39,000 / employee</span>
                      </div>
                      <div className="w-full flex justify-between pb-4" style={{borderBottom: "1px solid #E5E7EB"}}>
                        <span className="text-black text-sm">Regulatory Fine  </span>
                        <span className="text-black text-sm font-medium">$9,991 / employee</span>
                      </div>
                      <div className="w-full flex justify-between pb-2">
                        <span className="text-black text-sm">Cyber Incident </span>
                        <span className="text-black text-sm font-medium">$130,000</span>
                      </div>
                    </div>
                </div>
              </div>
              <div className="w-full lg:w-7/12 px-[0] lg:px-[12px] py-[12px]">
                <div className="card-view p-[24px] lg:p-[48px] pb-0">
                    <span className="text-gray text-sm">Did you know?</span>
                    <h4 className="text-xl mt-2 mb-4">Average Time Spent Resolving Items Manually</h4>
                    <div className="card-content">
                      <div className="w-full flex justify-between pb-4" style={{borderBottom: "1px solid #E5E7EB"}}>
                        <span className="text-black text-sm">Manual Analyst Time</span>
                        <span className="text-black text-sm font-medium">$25-$80 / hour</span>
                      </div>
                      <div className="w-full flex justify-between pb-4" style={{borderBottom: "1px solid #E5E7EB"}}>
                        <span className="text-black text-sm">Manual Investigatory Time </span>
                        <span className="text-black text-sm font-medium">2 person hours</span>
                      </div>
                      <div className="w-full flex justify-between pb-4" style={{borderBottom: "1px solid #E5E7EB"}}>
                        <span className="text-black text-sm">Manual Search </span>
                        <span className="text-black text-sm font-medium">5 person hours</span>
                      </div>
                      <div className="w-full flex justify-between pb-4" style={{borderBottom: "1px solid #E5E7EB"}}>
                        <span className="text-black text-sm">Manual Sorting Through Data </span>
                        <span className="text-black text-sm font-medium">1.25 person hours</span>
                      </div>
                      <div className="w-full flex justify-between pb-2">
                        <span className="text-black text-sm">Total Manual Process Time Per Incident/Item </span>
                        <span className="text-black text-sm font-medium">3.75 person hours</span>
                      </div>
                    </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap mx-[0] lg:mx-[-24px]">
              <div className="w-full lg:w-7/12 px-[0] lg:px-[12px] py-[12px]">
                <div className="card-view h-full p-[24px] lg:p-[48px] pb-0 flex flex-col justify-between">
                   <div>
                    <span className="text-gray text-sm">Did you know?</span>
                      <h4 className="text-xl my-2">Highlighted for 2nd review</h4>
                      <p className="text-gray text-sm mb-[32px]">When prospects are highlighted for further Analyst Review/Secondary Review</p>
                   </div>
                    <div className="card-content">
                      <div className="w-full flex justify-between pb-4" style={{borderBottom: "1px solid #E5E7EB"}}>
                        <span className="text-black text-sm">Manual Analyst Time</span>
                        <span className="text-black text-sm font-medium">$25-$80 / hour</span>
                      </div>
                      <div className="w-full flex justify-between pb-4" style={{borderBottom: "1px solid #E5E7EB"}}>
                        <span className="text-black text-sm">Manual Investigatory Time </span>
                        <span className="text-black text-sm font-medium"> ⅓ hour</span>
                      </div>
                      <div className="w-full flex justify-between pb-4" style={{borderBottom: "1px solid #E5E7EB"}}>
                        <span className="text-black text-sm">Manual Search </span>
                        <span className="text-black text-sm font-medium">½ hour</span>
                      </div>
                      <div className="w-full flex justify-between pb-4" style={{borderBottom: "1px solid #E5E7EB"}}>
                        <span className="text-black text-sm">Manual Sorting Through Data </span>
                        <span className="text-black text-sm font-medium">¼ hour</span>
                      </div>
                      <div className="w-full flex justify-between pb-2">
                        <span className="text-black text-sm">Total Manual Process Time Per Incident/Item </span>
                        <span className="text-black text-sm font-medium">1 person hour</span>
                      </div>
                    </div>
                </div>
              </div>
              <div className="w-full lg:w-5/12 px-[0] lg:px-[12px] py-[12px]">
                <div className="card-view black p-[24px] lg:p-[48px] pb-0 ">
                    <span className="text-darkGreen text-sm">ROI #1</span>
                    <h4 className="text-xl my-2 text-white">Detailed ROI Breakdown with ThreatMinder Fusion Network</h4>
                    <p className="text-lightGray text-sm">
                      <span className="text-medium text-white">Use Case: </span> 
                      Onboarding new customer, SMB, Person, Business Identity Confirmation/Identity Risk/Fraud Risk, Onboard Processing
                    </p>
                    <div className="flex flex-wrap  mx-[-10px] mb-4">
                      <span className="badge">Efficiency</span>
                      <span className="badge">Effectiveness</span>
                      <span className="badge">No Manual Processes</span>
                      <span className="badge">No Manual Sort</span>
                      <span className="badge">No Manual Search</span>
                      <span className="badge">FAST</span>
                    </div>
                    <div className="card-content" style={{paddingBottom: 10}}>
                      <div className="w-full flex justify-between pb-4" style={{borderBottom: "1px solid #E5E7EB"}}>
                        <span className="text-black text-sm">Green Light</span>
                        <span className="text-black text-sm   font-medium flex items-center gap-1">
                          17.43% more customers immediately
                          <IconArrowUp/>
                        </span>
                      </div>
                      <div className="w-full flex justify-between pb-4" style={{borderBottom: "1px solid #E5E7EB"}}>
                        <span className="text-black text-sm">Decrease</span>
                        <span className="text-black text-sm font-medium flex items-center gap-1">
                          11.54% fraud immediately
                          <IconArrowDown/>
                        </span>
                      </div>
                      <div className="w-full flex justify-between pb-4" style={{borderBottom: "1px solid #E5E7EB"}}>
                        <span className="text-black text-sm">Manual Review Processes</span>
                        <span className="text-black text-sm font-medium flex items-center gap-1">
                          Decreased by 57.8%
                          <IconArrowDown/>
                        </span>
                      </div>
                      <div className="w-full flex justify-between">
                        <span className="text-black text-base font-medium">Save</span>
                        <span className="text-black text-base font-medium">
                          <span className="text-darkGreen">$135,000 </span>per MONTH</span>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div className="w-full px-[0px] ">
                <div className="background-image card-view flex flex-wrap black p-[24px] lg:p-[48px] pb-0 mx-[0] lg:mx-[-12px]  ">
                    <div className="w-full lg:w-1/2 flex flex-col justify-between p-[0] lg:pr-4 pb-[48px] gap-[24px]">
                      <div>
                        <span className="text-darkGreen text-sm">ROI #2</span>
                        <h4 className="text-xl my-2 text-white">Detailed ROI Breakdown with ThreatMinder Fusion Network</h4>
                        <p className="text-lightGray text-sm">
                          <span className="text-medium text-white">Use Case: </span> 
                          Analyst Investigation, Insider Threat, Investigation
                        </p>
                      </div>
                      <div className="flex flex-wrap mx-[-10px]">
                        <span className="badge">Efficiency</span>
                        <span className="badge">Effectiveness</span>
                        <span className="badge">No Manual Processes</span>
                        <span className="badge">No Manual Sort</span>
                        <span className="badge">No Manual Search</span>
                        <span className="badge">FAST</span>
                        <span className="badge white">FOCUSED Results</span>
                      </div>
                      <button className="text-white" onClick={openHubspotForm}>Book a Demo</button>
                    </div>
                    <div className="card-content w-full lg:w-1/2" style={{paddingBottom: 10}}>
                      <div className="w-full flex justify-between pb-4" style={{borderBottom: "1px solid #E5E7EB"}}>
                        <span className="text-black text-sm">EFFICIENCY IN ONBOARDING</span>
                        <IconCheck/>
                      </div>
                      <div className="w-full flex justify-between pb-4" style={{borderBottom: "1px solid #E5E7EB"}}>
                        <span className="text-black text-sm">Manual Investigatory Time</span>
                        <span className="text-darkGreen text-sm font-medium">ZERO</span>
                      </div>
                      <div className="w-full flex justify-between pb-4" style={{borderBottom: "1px solid #E5E7EB"}}>
                        <span className="text-black text-sm">Manual Sorting Through Data</span>
                        <span className="text-darkGreen text-sm font-medium">ZERO</span>
                      </div>
                      <div className="w-full flex justify-between pb-[24px] md:pb-[70px]" style={{borderBottom: "1px solid #E5E7EB"}}>
                        <span className="text-black text-sm">TOTAL MANUAL PROCESS TIME PER INCIDENT/ITEM USING TMFN</span>
                        <span className="text-black text-sm font-medium">.001</span>
                      </div>
                      <div className="w-full flex justify-between">
                        <span className="text-black text-base font-medium">Save</span>
                        <span className="text-black text-base font-medium">
                          <span className="text-darkGreen">$81,345 </span>per MONTH</span>
                      </div>
                    </div>
                </div>
             </div>
        </div>

      </section>

      <section id="section-testimonials" className=" py-[80px] md:py-[128px] gap-[48px] lg:gap-[0]" >
          <div className="text-content flex flex-col justify-between" >
              <div className="flex flex-col items-center lg:items-start ">
                <div className="flex w-fit mb-3">
                  <div className="point"></div>
                  <div className="point"></div>
                  <div className="point"></div>
                </div>
                <span className="text-[14px] text-darkGreen font-medium">Testimonials</span>
                <h1 className="text-white mt-[12px] mb-[20px]">Customer Stories</h1>
                <p className="max-w-[550px] text-lightGray text-[16px] text-center text-lg-start">
                You are not alone! We have solved the manual processes, the incomplete processes and the inefficient processes for others as well.
                </p>
              </div>
              <div className="max-w-[550px] hidden lg:flex flex-col gap-[20px]">
                <Carousel
                  ref={testimonialsCarouselRef1}
                  responsive={testimonialsCarouselResponsive}
                  infinite={true}
                >
                  <div className="flex flex-col gap-[20px]">
                    <p className="max-w-[550px] text-white text-[16px] text-center text-lg-start">
                      “ThreatMinder, really knows how to save us money. Their Use Case based AI Analytics made our processes so much better: automated and efficient.  NO more hordes of manual reviewers sitting at desks costing us a fortune”
                    </p>
                    <div className="flex items-center gap-[12px]">
                        <img className="w-[36px] h-[36px]" src={require("../../assets/img/img-avatar.png")} alt=""/>
                        <div>
                          <p className="text-[14px] text-white mb-1">John Abrams</p>
                          <p className="text-[12px] text-gray font-medium">CFO, Major Financial Credit Card Provider</p>
                        </div>
                    </div>
                  </div>
                </Carousel>
                <div className="hidden lg:flex gap-[16px]">
                  <div className="btn-carousel left" onClick={() => handleMoveTestimonialsCarousel("previous")}><IconArrowChevronLeft/></div>    
                  <div className="btn-carousel right" onClick={() => handleMoveTestimonialsCarousel("next")}><IconArrowChevronRight/></div>    
                </div>
              </div>

          </div>
          <div className="my-[0] lg:my-[-24px]">
            <Carousel
                ref={testimonialsCarouselRef2}
                responsive={testimonialsCarouselResponsive}
                infinite={true}
                containerClass={`custom-carousel-container`}
                itemClass="card-item-container"
              >
                <div className="card-content">
                  <div className="flex flex-col gap-[16px] mb-[32px]">
                    <p className="text-[20px] text-black font-medium">Major Financial Credit Provider</p>
                    <p className="text-[14px] text-darkGray font-medium">
                      <span className="text-black"> Use case: </span>
                      Onboarding Fraud/False Identity
                    </p>
                    <p className="text-[14px] text-darkGray font-medium">
                      <span className="text-black"> Need: </span>
                      Each fraud incident cost approximately $30,000. Need to accurately and quickly review data and confirm identity.
                    </p>
                  </div>

                  <div className="w-full flex flex-col">
                    <span className="text-[12px] text-darkGreen text-medium">TM Fusion Network Integrated</span>
                    <span className="text-[16px] text-black text-medium">Results:</span>

                    <div className="w-full flex mt-[16px]">
                      <div className="w-1/2 flex flex-col gap-[16px] pl-[12px]">
                        <div className="flex gap-2">
                            <IconCheck />
                            <p className="text-black">17.8% decrease in fraud</p>
                        </div>
                        <div className="flex gap-2">
                            <IconCheck />
                            <p className="text-black">19.65% decrease in identity fraud</p>
                        </div>
                        <div className="flex gap-2">
                            <IconCheck />
                            <p className="text-black">23.97% decrease in regulatory fines</p>
                        </div>

                      </div>
                      <div className="w-1/2 flex flex-col gap-[16px] pr-[12px]">
                        <div className="flex gap-2">
                            <IconCheck />
                            <p className="text-black">27.21% cost savings on operations</p>
                        </div>
                        <div className="flex gap-2">
                            <IconCheck />
                            <p className="text-black">56.1% lower manual time</p>
                        </div>
                        <div className="flex gap-2">
                            <IconCheck />
                            <p className="text-black">35.67% faster onboarding</p>
                        </div>
                      </div>

                    </div>

                    <div className="flex mt-[24px] pt-[24px] font-medium" style={{borderTop: "1px solid #E5E7EB"}}>
                      <span className="text-black">Save</span>
                      <span className="ml-auto">
                        <span className="text-darkGreen">$11.89 </span>
                        Million YEAR 1
                      </span>
                    </div>
                  </div>
                  
                  <button className="w-full text-white h-[50px]" style={{background: "#111827"}} onClick={openHubspotForm}>Book a Demo</button>

                </div>

            </Carousel>
          </div>
          <div className="text-content flex lg:hidden flex-col items-center gap-[20px]">
            <Carousel
                ref={testimonialsCarouselRef3}
                responsive={testimonialsCarouselResponsive}
                containerClass={`w-full`}
                itemClass="w-full"
                infinite={true}
              >
              <div className="flex flex-col items-center gap-[20px]">
                <p className="max-w-[550px] text-white text-[16px] text-center text-lg-start">
                  “ThreatMinder, really knows how to save us money. Their Use Case based AI Analytics made our processes so much better: automated and efficient.  NO more hordes of manual reviewers sitting at desks costing us a fortune”
                </p>
                <div className="flex items-center gap-[12px]">
                    <img className="w-[36px] h-[36px]" src={require("../../assets/img/img-avatar.png")} alt=""/>
                    <div>
                      <p className="text-[14px] text-white mb-1">John Abrams</p>
                      <p className="text-[12px] text-gray font-medium">CFO, Major Financial Credit Card Provider</p>
                    </div>
                </div>
              </div>
            </Carousel>
          </div>
          <div className="flex lg:hidden gap-[16px] mx-auto">
              <div className="btn-carousel" onClick={() => handleMoveTestimonialsCarousel("previous")}><IconArrowChevronLeft/></div>    
              <div className="btn-carousel" onClick={() => handleMoveTestimonialsCarousel("next")}><IconArrowChevronRight/></div>    
          </div>

      </section>

      <section id="section-things-to-love" className=" py-[80px] md:py-[128px] ">
        <div className="w-full flex flex-col gap-[56px] items-center">
          <div className="flex flex-col items-center">
            <button className="black relative " style={{ borderRadius: "100px" }}>
                Search <span className="text-black">{textArray[textIndex]}</span>
                <div className="flex flex-col scrollButton">
                  <button className="black pl-0 w-max scrollText">Simplified</button>
                  <button className="black pl-0 scrollText">Analyzed</button>
                  <button className="black pl-0 scrollText">Amplified</button>
                  <button className="black pl-0 w-max scrollText">Force Multiplied</button>
                </div>
            </button>
            <h1 className="z-10 text mt-2 mb-3 text-[28px] md:text-[40px] text-center max-w-[550px]">A Few More Things You’re Going To Love</h1>
            <p className="text-gray text-base text-center max-w-[480px]">
            Your Costs Today. Current processes result in expensive and inefficient results
            </p>
          </div>
          <div className="w-full flex flex-col gap-[16px]">
          <div className="card-content flex-col lg:flex-row">
              <div className="w-full lg:w-1/2 mt-[0] lg:mt-[56px] pt-[24px] px-[24px] md:pl-[48px] flex flex-col gap-[16px] max-w-[550px] lg:max-w-[650px]">
                <p className="text-black text-[28px] font-medium">Mapped Results Explain the WHY</p>
                <ul>
                  <li>Know WHY each item scored the way it is </li>
                  <li>The WHY helps in decisioning</li>
                  <li>Real-time algorithm updates = scenario testing for analysts</li>
                  <li>Hidden complexity empowers your team</li>
                </ul>
                <a className="try-now-link text-[16px] font-medium text-darkGray hover:text-darkGreen" onClick={openHubspotForm}>
                  Try now 
                  <IconArrowRight className="icon"/>
                </a>
              </div>
              <div className="w-full lg:w-1/2 mt[24px] md:mt-[56px]">
                  <img  src={require("../../assets/img/img-demo-page-1.png")} alt=""/>
              </div>
            </div>
            <div className="card-content flex-col-reverse lg:flex-row">
              <div className="w-full lg:w-1/2 mt-[24px] md:mt-[56px] pr-[0] lg:pr-[24px] ">
                  <img  src={require("../../assets/img/img-demo-page-2.png")} alt=""/>
              </div>
              <div className="w-full px-[24px] pr-[24px] lg:pr-[48px] lg:w-1/2 mt-[0] lg:mt-[56px] pt-[24px] flex flex-col gap-[16px] max-w-[550px] lg:max-w-[650px]">
                <p className="text-black text-[28px] font-medium">Time Savings</p>
                <p className="text-[14px] text-darkGray">We save you thousands of dollars per month from inefficient, manual, and time consuming processes.  Save $$$, grow your revenue.</p>
                <a className="try-now-link text-[16px] font-medium text-darkGray" onClick={openHubspotForm}>
                  Try now 
                  <IconArrowRight className="icon"/>
                </a>
              </div>
            </div>

            <div className="card-content flex-col lg:flex-row">
              <div className="w-full lg:w-1/2 mt-[0] lg:mt-[56px] pt-[24px] px-[24px] md:pl-[48px] flex flex-col gap-[16px] max-w-[550px] lg:max-w-[650px]">
                <p className="text-black text-[28px] font-medium">Efficiency for your Teams</p>
                <ul>
                  <li>Focus on the what matters</li>
                  <li>Powerful Risk and Relevancy Scoring</li>
                  <li>Change in Real-Time</li>
                  <li>Multi layered understanding</li>
                  <li>At Scale</li>
                </ul>
                <a className="try-now-link text-[16px] font-medium text-darkGray hover:text-darkGreen" onClick={openHubspotForm}>
                  Try now 
                  <IconArrowRight className="icon"/>
                </a>
               </div>
              <div className="w-full lg:w-1/2 mt[24px] md:mt-[56px]">
                  <img  src={require("../../assets/img/img-demo-page-3.png")} alt=""/>
              </div>
            </div>
            <div className="card-content flex-col-reverse lg:flex-row">
              <div className="w-full lg:w-1/2 mt-[24px] md:mt-[56px] pr-[0] lg:pr-[24px] ">
                  <img  src={require("../../assets/img/img-demo-page-4.png")} alt=""/>
              </div>
              <div className="w-full px-[24px] pr-[24px] lg:pr-[48px] lg:w-1/2 mt-[0] lg:mt-[56px] pt-[24px] flex flex-col gap-[16px] max-w-[550px] lg:max-w-[650px]">
                <p className="text-black text-[28px] font-medium">Data Quality Improvements</p>
                <ul>
                  <li>Lower financial costs and burden with analyzed and scored data</li>
                  <li>Save huge $$$ by using mapped, scored, and analyzed data results</li>
                  <li>Custom and unique Use Cases drive better results – for all your organizations</li>
                </ul>
                <a className="try-now-link text-[16px] font-medium text-darkGray" onClick={openHubspotForm}>
                  Try now 
                  <IconArrowRight className="icon"/>
                </a>
              </div>
            </div>
            <div className="card-content flex-col lg:flex-row">
              <div className="w-full lg:w-1/2 mt-[0] lg:mt-[56px] pt-[24px] px-[24px] md:pl-[48px] flex flex-col gap-[16px] max-w-[550px] lg:max-w-[650px]">
                <p className="text-black text-[28px] font-medium">Powerful Categorization</p>
                <ul>
                  <li>Each item mapped to categories</li>
                  <li>Instant knowledge of what is driving results</li>
                  <li>Powerful insights drives efficiency</li>
                  <li>Save huge $$$ on time spent per item</li>
                </ul>
                <a className="try-now-link text-[16px] font-medium text-darkGray hover:text-darkGreen" onClick={openHubspotForm}>
                  Try now 
                  <IconArrowRight className="icon"/>
                </a>
              </div>
              <div className="w-full lg:w-1/2 mt[24px] md:mt-[56px]">
                  <img  src={require("../../assets/img/img-demo-page-5.png")} alt=""/>
              </div>
            </div>
            <div className="card-content flex-col-reverse lg:flex-row">
              <div className="w-full lg:w-1/2 mt-[24px] md:mt-[56px] pr-[0] lg:pr-[24px] ">
                  <img  src={require("../../assets/img/img-demo-page-6.png")} alt=""/>
              </div>
              <div className="w-full px-[24px] pr-[24px] lg:pr-[48px] lg:w-1/2 mt-[0] lg:mt-[56px] pt-[24px] flex flex-col gap-[16px] max-w-[550px] lg:max-w-[650px]">
                <p className="text-black text-[28px] font-medium">Customizable Metrics</p>
                <p className="text-[14px] text-darkGray">
                Customize Metrics by specifying use cases, search keys and weightings that are most relevant to your organization.
                </p>
                <a className="try-now-link text-[16px] font-medium text-darkGray" onClick={openHubspotForm}>
                  Try now 
                  <IconArrowRight className="icon"/>
                </a>
              </div>
            </div>
          </div>

        </div>

      </section>

      <section id="section-faq" className=" py-[80px] md:py-[128px] ">
          <div className="w-full lg:w-4/12 pr-0 lg:pr-[32px] flex flex-col items-center lg:items-start mb-[58px]">
            <span className="text-[14px] text-darkGreen font-medium">FAQ</span>
              <h1 className="text-white mt-[12px] mb-[20px]">Frequently Asked Questions</h1>
                <p className="max-w-[550px] text-lightGray text-[16px] text-center text-lg-start">
                  Have a question that is not answered? You can contact us at 
                  <a href="mailto:support@threatminder.com" className="text-white font-mefium hover:underline"> support@threatminder.com</a>
                </p>
          </div>
          <div className="w-full lg:w-8/12 pl-0 lg:pl-[32px] flex flex-col gap-[32px]">
            <div className=" flex flex-col gap-[16px]">
              {
                faq1.map((item, index)=> (
                  <div className={`card-content ${item.isOpen ? "active" : ""}`} key={index} onClick={() => collapseFAQ1(item)}>
                    <div className="w-full flex justify-between items-center">
                      <p className="text-white font-medium">{item.question}</p>
                      <div className={`btn-collapse `} >
                        {
                          item.isOpen 
                          ?  <IconArrowCaretUp  color="black" /> 
                          : <IconArrowCaretDown  color="white" />
                        }
                      </div>
                    </div>
                    <div className="mt-[16px]">
                    {
                      item.answer.split('\n').map((item, index) => 
                      <p className={`txt-answer text-lightGray ${item.includes("\t") ? "pl-5 mt-[5px]" : ""}`}>{item}</p>
                      )
                    }
                    </div>

                  </div>
                ))
              }
            </div>
            <div className=" flex flex-col gap-[16px]">
              <span className="text-[14px] font-bold text-lightGray">WHAT IF?</span>
              {
                faq2.map((item, index)=> (
                  <div className={`card-content ${item.isOpen ? "active" : ""}`} key={index}  onClick={() => collapseFAQ2(item)}>
                    <div className="w-full flex justify-between items-center">
                      <p className="text-white font-medium">{item.question}</p>
                      <div className={`btn-collapse `}>
                        {
                          item.isOpen 
                          ?  <IconArrowCaretUp  color="black" /> 
                          : <IconArrowCaretDown  color="white" />
                        }
                      </div>
                    </div>
                      <p className="txt-answer text-lightGray mt-[16px]">{item.answer}</p>

                  </div>
                ))
              }
            </div>
          </div>

      </section>
      
      <section id="section-cta" className=" py-[80px] md:py-[128px]">
        <div className="background-image">
          <div className="flex flex-col items-center justify-center">
            <span className="text-darkGreen text-sm text-center">Get Started</span>
            <h1 className="mt-2 mb-3 text-[28px] md:text-[40px] text-center max-w-[550px]">Start Automating your Search Journey with ThreatMinder</h1>
          </div>
        </div>
        <div className="my-[56px] flex flex-col items-center gap-[12px]">
              <div>
                <button className="black mr-[16px]" onClick={openHubspotForm}>Schedule a Demo</button>
                <button onClick={openHubspotForm}>Talk to sales</button>
              </div>
              <p className="text-[14px] text-darkGray">
                Already have an account?{ ' '}
                <a href="https://app.threatminder.com" target="_blank" className="text-darkGreen cursor-pointer hover:underline">Log In</a>
              </p>
        </div>
            <div className="panel">
              <div className="panel-header">
                <div className="search-bar text-center">threatminder.com</div>
              </div>
              <div className="panel-main">
                <img className="w-full hidden md:block" src={require("../../assets/img/img-demo-page-7.png")} alt=""/>
                <img className="w-full block md:hidden" src={require("../../assets/img/img-demo-page-8.png")} alt=""/>
              </div>
            </div>
      </section>

            
    </div>
  );
};

export default HomePage;
