import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import HomePage from '../components/home-page';
import MainLayout from '../components/layouts/MainLayout';


const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/" element={<HomePage />} />
      </Route>
     
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes;
