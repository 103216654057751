import React from "react";
import { ProgressBar } from "react-bootstrap";

const HIGH = "high";
const MEDIUM = "medium";
const LOW = "low";

const SubRisk = ({ name, percent, level, className }) => {
  const getVariant = (level) => {
    switch (level.toLowerCase()) {
      case HIGH:
        return "danger";
      case MEDIUM:
        return "warning";
      case LOW:
        return "success";
      default:
        return "success";
    }
  };

  return (
    <div
      className={`flex flex-col p-[12px] gap-[8px] text-[12px] text-black border-solid border-1 border-outline rounded-[12px]  ${className}`}
    >
      <span className="font-medium">{name}</span>
      <ProgressBar
        now={percent}
        className="h-[12px] "
        variant={getVariant(level)}
      />
      <div className="w-full flex justify-between">
        <span className="text-gray">
          Risk:{" "}
          <span className={`text-${getVariant(level)} text-uppercase`}>
            {level}
          </span>
        </span>
        <span className="font-medium">{percent}</span>
      </div>
    </div>
  );
};

export default SubRisk;
