import React, { useState } from "react";
import {  OverlayTrigger,  Tooltip } from "react-bootstrap";
import RangeSlider from "react-range-slider-input";

const RiskRange = ({ name, percent, className }) => {
  const [value, setValue] = useState(percent);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {value}
    </Tooltip>
  );
  return (
    <div
      className={`w-full flex p-[12px]  text-[12px] font-medium border-solid border-1 border-outline rounded-[12px]  ${className}`}
    >
      <div className="w-2/12">{name}</div>
      <OverlayTrigger overlay={renderTooltip} placement="top">
        <div className="w-10/12">
          <RangeSlider
            className="single-thumb "
            defaultValue={[0, value]}
            thumbsDisabled={[true, false]}
            rangeSlideDisabled={true}
          />
          <div className="flex justify-between mt-2">
            <span>1</span>
            <span>100</span>
          </div>
        </div>
      </OverlayTrigger>
    </div>
  );
};

export default RiskRange;
