import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import { Modal } from "react-bootstrap";
import { ReactComponent as IconClose } from "../../assets/icon/icon-close.svg";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import { HubspotContext } from "../../context/HubspotContext";

const styles = {
  modalHeader: {
    fontFamily: "arial",
    color: "#212D3A",
    textAlign: "center",
  }
}

const MainLayout = () => {
  const {  closeHubspotForm, showHubspotModal } = useContext( HubspotContext );
  return (
    <div>
      <Header  />
      <Outlet  />
      <Footer />
      <Modal
        size="lg"
        centered
        show={showHubspotModal}
        onHide={closeHubspotForm}
      >
        <Modal.Header className="flex">
          <Logo className="logo text mx-auto" />
          <IconClose
            className="cursor-pointer h-[30px] w-[30px]"
            onClick={closeHubspotForm}
          />
        </Modal.Header>
        <Modal.Body className=" px-[12px] md:px-[24px] ">
          <p style={styles.modalHeader} >
            Thank you for your interest in ThreatMinder. Please complete the
            information below and our team will connect with you shortly.
          </p>
          <div className="hubspotForm mt-[16px]"></div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MainLayout;
