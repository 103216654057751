import React, { useEffect } from "react";
import $ from "jquery";
import "./style.css";

import "react-multi-carousel/lib/styles.css";
import { ReactComponent as IconClose }  from '../../../assets/icon/icon-close.svg'
import { ReactComponent as IconCursor } from "../../../assets/icon/icon-cursor.svg";
import { ReactComponent as IconSearch } from "../../../assets/icon/icon-search.svg";
import { ReactComponent as IconNoteSearch } from "../../../assets/icon/icon-note-search.svg";
import { NETWORK_UI_TAB } from "../../../shared/constant";


const UsualProcressPanel = ({ setTab }) => {
  const searchResult= [1, 2, 3, 4]

  const animationAction = () => {
      $(".usual-procress-panel #icon-cursor").removeAttr('class');
      $(".usual-procress-panel .typing-text").css("display", "none");
      $(".usual-procress-panel .search-content").css("display", "flex");
      $(".usual-procress-panel .result-content").css("display", "none");
      $(".usual-procress-panel .search-text").css("display", "none");
      $(".usual-procress-panel .result-items-load").css("display", "none");
      $(".usual-procress-panel .result-items").css("display", "none");
      

    // Move mouse to input field
    setTimeout(() => {
      $(".usual-procress-panel #icon-cursor").css("display", "flex");
    }, 1000);

    // Show entering text
    setTimeout(() => {
      $(".usual-procress-panel #icon-cursor").css("display", "none");
      $(".usual-procress-panel .typing-text").css("display", "block");
    }, 2500);

    // Show clicking search button
    setTimeout(() => {
      $(".usual-procress-panel #icon-cursor").addClass("search");
      $(".usual-procress-panel #icon-cursor").css("display", "flex");
    // }
    }, 3500);

    // Show the result search: input bar
    setTimeout(() => {
      $(".usual-procress-panel .search-content").css("display", "none");
      $(".usual-procress-panel .result-content").css("display", "flex");
      $(".usual-procress-panel .search-text").css("display", "flex");
    }, 6000);


    // Show the result search: loading posts
    setTimeout(() => {
      $(".usual-procress-panel .result-items-load").css("display", "flex");
    }, 6500);

    // Show the result search: posts
    setTimeout(() => {
      $(".usual-procress-panel .result-items-load").css("display", "none");
      $(".usual-procress-panel .result-items").css("display", "flex");
    }, 8000);

    // Move mouse to network ui tab
    setTimeout(() => {
      $(".usual-procress-panel #icon-cursor").addClass("move-to-network-ui-tab");
      $(".usual-procress-panel #icon-cursor").css("display", "flex");
    }, 10000);

    // Show network ui tab
    setTimeout(() => {
      setTab(NETWORK_UI_TAB)
      $(".usual-procress-panel #icon-cursor").css("display", "none");
    }, 11500);
    
  }

  useEffect(() => {
    var id = window.setTimeout(function() {}, 0);

    while (id--) {
        window.clearTimeout(id); 
    }
    animationAction()
  }, []);

  return (
    <div className="usual-procress-panel">
      <div className="search-content">
        <IconNoteSearch />
        <h2>Generic Search</h2>
        <div className="input-box w-1/2 h-[42px]">
          <IconSearch />
          <div className={`typing-text `}>
            John’s Pizza Shop Memphis, TN...|
          </div>
        </div>
        <button className="gray">Search</button>
        
      </div>
      <div className="result-content hidden w-full pb-[15px] ">
        <div className="w-full h-[550px] max-w-[550px] flex flex-column gap-[12px] overflow-y-auto pt-[24px] px-[12px] lg:px-[48px]">
          <div
            className="input-box search-text max-w-[550px] w-full"
          >
            <IconSearch />
            <div>John’s Pizza Shop Memphis, TN</div>
            <IconClose className="cursor-pointer ml-auto" />
          </div>
          <div className="result-items-load hidden flex-col gap-[16px] pt-[28px] overflow-hidden">
            {searchResult.map((item) => (
              <div className="flex flex-col gap-[4px] text-[10px]">
                <div className="h-[30px] w-5/12 bg-outline"></div>
                <div className="h-[30px] w-4/12 bg-outline"></div>
                <div className="h-[30px] w-full bg-outline"></div>
              </div>
            ))}
          </div>
          <div className="result-items hidden flex-col gap-[16px] pt-[28px]">
            {searchResult.map((item) => (
              <div className="flex flex-col gap-[4px] max-w-[470px] text-[10px]">
                <div className="flex gap-[4px] items-center">
                  <img
                    className="w-[24px] h-[24px]"
                    src={require("../../../assets/img/img-avatar-empty.png")}
                    alt=""
                  />
                  <div>
                    <p>Papa John’s Pizza</p>
                    <p className="text-gray">https://locations.papajohns.com</p>
                  </div>
                </div>
                <p className="text-[14px]">Poplar Ave - Memphis</p>
                <p className="text-gray">
                  Order online or call (901) 766-9988 now for the best pizza
                  deals. Taste our latest menu options for pizza, breadsticks
                  and wings. Available for delivery or ...{" "}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <IconCursor className="icon " id="icon-cursor"/>

      <div className="message-box box-top-right">
          <p>Manual searches take too long😒</p>
          <div className="flex items-center gap-[12px]">
            <img
              className="w-[36px] h-[36px]"
              src={require("../../../assets/img/img-avatar.png")}
              alt=""
            />
            <div>
              <p className="text-[14px] text-white mb-1">John Abrams</p>
              <p className="text-[12px] text-gray font-medium">
                CFO, Major Financial Credit Card Provider
              </p>
            </div>
          </div>
        </div>

        <div className="message-box box-bottom-left">
          <p>We are missing critical info with our current processes... 😓</p>
          <div className="flex items-center gap-[12px]">
            <img
              className="w-[36px] h-[36px]"
              src={require("../../../assets/img/img-avatar-1.png")}
              alt=""
            />
            <div>
              <p className="text-[14px] text-white mb-1">Kathryn Murphy</p>
              <p className="text-[12px] text-gray font-medium">
                CFO, Major Financial Credit Card Provider
              </p>
            </div>
          </div>
        </div>
    </div>
  );
};

export default UsualProcressPanel;
