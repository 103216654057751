import React, { useContext, useEffect, useState } from 'react';
import './style.css'
import iconMenu from '../../../assets/icon/icon-menu.svg'
import { ReactComponent as Logo }  from '../../../assets/img/logo.svg'
import { HubspotContext } from '../../../context/HubspotContext';

const Header = () => {
  const { openHubspotForm } = useContext(HubspotContext);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = (event) => {
        event.stopPropagation()
        setMenuOpen(!menuOpen);
    };

  const scrollToSection = (event, sectionId) => {
    event.stopPropagation()
    setMenuOpen(false)
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuOpen && !event.target.closest(".menu-collapse")) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuOpen]);
  
  const handleLogin = () => {
    window.open('https://app.threatminder.com', '_blank')
  }

  
  return (
    <header>
      <Logo className='logo mr-auto'/>
      <nav className="hidden lg:block mr-auto">
        <ul>
          <li onClick={(event) => scrollToSection(event, 'section-our-solution')}>Fusion Network</li>
          <li onClick={(event) => scrollToSection(event, 'section-how-it-work-2')}>ROI w TM</li>
          <li onClick={(event) => scrollToSection(event, 'section-faq')}>FAQ</li>
          <li onClick={(event) => scrollToSection(event, 'section-our-solution')}>Grow with TM Fusion</li>
          <li onClick={(event) => scrollToSection(event, 'section-how-it-work-1')}>How it Works</li>
        </ul>
      </nav>
      <div className="hidden sm:flex" style={{ gap: '16px' }}>
        <button onClick={handleLogin}>Sign In</button>
        <button className="black hubspot4" id="hubspotFormContainer" onClick={openHubspotForm} >See a demo</button>
      </div>
      <div className="flex lg:hidden ">
          <img src={iconMenu} onClick={toggleMenu} alt=''/>
      </div>
      {menuOpen && (
        <div className="menu-collapse">
          <ul className="flex flex-col">
            <li className="px-3 py-2 text-center" onClick={(event) => scrollToSection(event, 'section-our-solution')}>Fusion Network</li>
            <li className="px-3 py-2 text-center" onClick={(event) => scrollToSection(event, 'section-how-it-work-2')}>ROI w TM</li>
            <li className="px-3 py-2 text-center" onClick={(event) => scrollToSection(event, 'section-faq')}>FAQ</li>
            <li className="px-3 py-2 text-center" onClick={(event) => scrollToSection(event, 'section-our-solution')}>Grow with TM Fusion</li>
            <li className="px-3 py-2 text-center" onClick={(event) => scrollToSection(event, 'section-how-it-work-1')}>How it Works</li>
          </ul>
          <div className="px-3 block sm:hidden"><hr /></div>
          <ul className="flex flex-col sm:hidden">
            <li className="px-3 py-2 text-center" onClick={handleLogin}>Sign In</li>
            <li className="px-3 py-2 text-center" onClick={openHubspotForm}>See a demo</li>
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;
